<template>
<section class="container">
    <div class="image">
      <img src="@/assets/img/girl-tablet.png" alt="laptop" />
    </div>
    <div class="text">
      <h2>Innovation takes birth in <span class="text-highlight">Zinc</span> with the evolution of your expectations</h2>
      <p>
        <OpinionCard />
      </p>
    </div>
  </section>
</template>

<script>
import OpinionCard from '@/components/OpinionCard.vue';

export default {
  name: 'RequestdemoContainer2',
  components: {
    OpinionCard,
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  background-color: $gray-lightest;
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
  "text"
  "image";
  gap: 2rem;
  color: $black;
  padding: $Gpadding $Gpadding 10rem $Gpadding;
  @include L {
    padding: 10rem 10rem 10rem 10rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text image";
  }
  .text {
    grid-area: text;
    margin: auto 0;
    font-size: 1.8rem;
    line-height: 3rem;
    padding: 0 2rem;
    max-width: 70rem;
    @include M {
      font-size: 2rem;
      line-height: 3.2rem;
      padding: 0 2rem;
    }
    @include L {
      padding: 0 8rem;
    }
    h2 {
      font-weight: 700;
      font-size: 3.5rem;
      line-height: 4rem;
      margin-bottom: 5rem;
      @include L {
        font-size: 4.5rem;
        line-height: 6.5rem;
        margin-bottom: 10rem;
      }
    }
    p {
      margin-top: 2rem;
    }
    &-highlight {
      color: $purple-light;
    }
  }

  .image {
    grid-area: image;
    text-align: center;
    background-image: url('../../assets/img/z-big.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    height: 100%;
    width: 90%;
    display: flex;
    justify-content: center;
    img {
      height: auto;
      width: auto;
      max-height: 30rem;
      max-width: 90%;
      display: block;
      margin: auto 0;
      margin-top: 0;
      @include M {
        width: 90%;
        height: auto;
        max-height: none;
        margin-top: auto;
      }
    }
  }
}
</style>
