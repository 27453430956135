<template>
<section class="container">
    <div class="title">
      <img src="@/assets/img/smartsecure.png" alt="smart secure">
      <h2>Great!</h2>
      <p>You are about to discover the Next Generation of deposits and fees management</p>
    </div>
    <div class="calendly-iframe">
      <div class="calendly-iframe-container" data-url="https://calendly.com/tom-zinc/zincwelcome">
        <iframe id="iframe-calendly" title="Zinc Calendly" width="100%" height="100%" loading="lazy" frameborder="0"
          src="https://calendly.com/tom-zinc/zincwelcome?embed_domain=zinc.tech&embed_type=Inline">
        </iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RequestdemoContainer1',
  components: {
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-image: linear-gradient(#765aa2, #3e3780, #482d89);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
  "title"
  "iframe";
  gap: 2rem;
  padding: 10rem $Gpadding $Gpadding $Gpadding;
  color: $white;
  @include M {
    padding: 10rem 5rem 0 10rem;
    grid-template-rows: auto;
    grid-template-columns: 40% 60%;
    grid-template-areas:
    "title iframe";
  }
  .title {
    grid-area: title;
    @include M {
      margin-bottom: auto;
      margin-top: 20rem;
    }
    h2 {
      font-size: 4rem;
      line-height: 6rem;
      font-weight: bold;
      @include M {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
    p {
      font-size: 3.5rem;
      line-height: 5rem;
    }
    img {
      width: 50%;
      margin-bottom: 4rem;
      max-width: 30rem;
    }
  }

  .calendly-iframe {
    grid-area: iframe;
    padding-top: 2rem;
    width: 100%;
    @include M {
      padding-top: 6rem;
    }
    &-container {
      width: 100%;
      height: 1200px;
      max-width: 99rem; // 64rem;
      margin: 0 auto;
      padding-bottom: 2rem;
      @include M {
        margin: 0;
      }
    }
  }
}
</style>
