<template>
  <div class="opinioncard">
    <div class="opinioncard-title">
      <div class="opinioncard-name">{{ reviews[currentReview].title }}</div>
      <div class="opinioncard-buttons">
        <img class="opinioncard-buttons-previous" src="@/assets/next.svg" alt="previus"  @click="handlePrevious" />
        <img class="opinioncard-buttons-next" src="@/assets/next.svg" alt="next" @click="handleNext" />
      </div>
    </div>
    <div class="opinioncard-text">{{ reviews[currentReview].text }}</div>
    <div class="opinioncard-stars"><span v-for="stars in reviews[currentReview].rating || 1" :key="stars">★</span></div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'OpinionCard',
  setup() {
    const reviews = [
      {
        title: 'Property Manager',
        text: 'Zinc is a valuable tool that has been able to streamline the collection of fees and deposits in a friendly and simple platform.',
        rating: 5,
      },
    ];

    const currentReview = ref(0);

    const handleNext = () => {
      if (currentReview.value < reviews.length - 1) {
        currentReview.value += 1;
      } else {
        currentReview.value = 0;
      }
    };

    const handlePrevious = () => {
      if (currentReview.value > 0) {
        currentReview.value -= 1;
      } else {
        currentReview.value = reviews.length - 1;
      }
    };

    return {
      reviews,
      currentReview,
      handleNext,
      handlePrevious,
    };
  },
};
</script>

<style lang="scss" scoped>
.opinioncard {
  margin-bottom: 10rem;
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
  }
  &-name {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: bold;
    color: $purple-light;
    margin-bottom: 5rem;
    margin: auto 0;
  }
  &-buttons {
    display: flex;
    align-items: center;
    &-previous, &-next {
      cursor: pointer;
    }
    &-next {
      margin-left: 2rem;
      transform: rotate(180deg);
      @include M {
        margin-left: 5rem;
      }
    }
  }
  &-text {
    font-size: 2.5rem;
    line-height: 4rem;
    color: #5F5F5F;
  }
  &-stars {
    font-size: 3.5rem;
    line-height: 2.5rem;
    margin-top: 10rem;
    & > * { margin-right: 1rem; }
  }
}
</style>
