<template>
  <Container1 />
  <Container2 />
</template>

<script>
import Container1 from '@/components/Requestdemo/Container1.vue';
import Container2 from '@/components/Requestdemo/Container2.vue';

export default {
  name: 'Requestdemo',
  components: {
    Container1,
    Container2,
  },
};
</script>

<style lang="scss" scoped>
</style>
